import loadable from '@loadable/component';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useHistory } from 'react-router-dom';

import { ReactComponent as Hamburger } from '../../images/icons/menu24px.svg';
import { ReactComponent as Logo } from '../../images/icons/logo.svg';
import { CONFIG_SHAPE } from '../../state/config/config';

import useMediaQuery from 'Utils/useMediaQuery';

import styles from './StickyHeader.module.scss';
import { CART } from 'Features/SwitchableFeature/SwitchableFeature.definitions';
import { usePopupOpened } from '../../utils/usePopupOpened';
import { ARD_POPUP_MENU } from '../../utils/appRoutes.definitions';
import { getPopupOnPagePath } from '../../utils/appRoutes';
import { useSwitchableFeatures } from '../../features/SwitchableFeature/SwitchableFeature';
import PopularSearchTerms from '../PopularSearchTerms';

const HeaderMenuItems = loadable(() => import('../HeaderMenuItems/HeaderMenuItems'));
const BookmarkPopup = loadable(() => import('../BookmarkPopup/BookmarkPopup'));
const NewsletterSettingsDrawer = loadable(() => import('../NewsletterSettingsDrawer/NewsletterSettingsDrawer'));
const PersonalInformationDrawer = loadable(() => import('../PersonalInformationDrawer/PersonalInformationDrawer'));
const RecentlyViewedPopup = loadable(() => import('../RecentlyViewedPopup/RecentlyViewedPopup'));
const MenuDrawer = loadable(() => import('../MenuDrawer/MenuDrawer'));
const Searchbar = loadable(() => import('../Searchbar/Searchbar'));
const ActiveFilters = loadable(() => import('../ActiveFilters/ActiveFilters'));

const StickyHeader = React.memo(
  ({
    activeFilters = [],
    rootCategorySlug,
    selectedCategoriesData = [],
    classList = {},
    config,
    profile,
    filters,
    searchTerm = '',
    isSearchPage = false,
    categoriesInUrl = [],
  }) => {
    const location = useLocation();
    const { push } = useHistory();
    const { root = '' } = classList;
    const prevScrollY = useRef(0);
    const headerRef = useRef(null);
    const [goingDown, setGoingDown] = useState(false);
    const { isGreaterOrEqualTo, BREAKPOINTS } = useMediaQuery();
    const isDesktop = isGreaterOrEqualTo(BREAKPOINTS.EXTRA_LARGE);
    const [menuPopupOpened] = usePopupOpened(ARD_POPUP_MENU);
    const [marketplaceHeader] = useSwitchableFeatures([CART]);
    const lastSubCategoryData = selectedCategoriesData && selectedCategoriesData[selectedCategoriesData.length - 1];
    const featured_queries = lastSubCategoryData?.featured_queries || [];

    useEffect(() => {
      const handleScroll = () => {
        //Prevent safari bounce from the top
        const currentScrollY = window.scrollY >= 0 ? window.scrollY : 0;
        if (prevScrollY.current > currentScrollY) {
          if (goingDown) {
            setGoingDown(false);
          }
        } else if (currentScrollY > 160 && !goingDown) {
          setGoingDown(true);
        }

        prevScrollY.current = currentScrollY;
      };

      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [push, location, goingDown]);

    return (
      <>
        <header
          className={classnames(root, styles.stickyHeader, {
            [styles.stickyHeaderMarketplace]: marketplaceHeader,
            [styles.hide]: goingDown,
          })}
          onClick={(e) => e.stopPropagation()}
          ref={headerRef}
        >
          <div className="container">
            <div className={classnames(styles.stickyHeaderContainer, styles.alignStickyHeader)}>
              <Link className={classnames(styles.hamburger)} to={getPopupOnPagePath(ARD_POPUP_MENU)} aria-label="Menu">
                <Hamburger />
              </Link>
              <Link to="/" className={classnames(styles.logo)} aria-label={config.shopName || 'Webshop'}>
                {marketplaceHeader && (
                  <>
                    {config.websiteName !== 'Webshop.nl' && (
                      <img
                        alt={config.shopName || 'Webshop'}
                        loading="lazy"
                        title={config.shopName || 'Webshop'}
                        src={config.theme.logos.main_logo_inverted}
                        width={110}
                        height={33}
                      />
                    )}
                    {config.websiteName === 'Webshop.nl' && (
                      <div className={styles.logoSvg}>
                        <Logo />
                      </div>
                    )}
                  </>
                )}
                {!marketplaceHeader && (
                  <picture>
                    <source media="(max-width: 1200px)" srcSet={config.theme.logos.main_logo_inverted} />
                    <source media="(min-width: 1200px)" srcSet={config.theme.logos.main_logo} />
                    <img
                      alt={config.shopName || 'Webshop'}
                      loading="lazy"
                      title={config.shopName || 'Webshop'}
                      src={config.theme.logos.main_logo_inverted}
                      width={110}
                      height={33}
                    />
                  </picture>
                )}
              </Link>
              <div className={classnames(styles.searchbar)}>
                <Searchbar
                  activeFilters={activeFilters}
                  initialValue={searchTerm}
                  autoFocus={false}
                  isDesktop={isDesktop}
                  searchTerm={searchTerm}
                  filters={filters}
                  rootCategorySlug={rootCategorySlug}
                  selectedCategory={selectedCategoriesData?.[0]}
                  isSearchPage={isSearchPage}
                  SAYT={false}
                />
              </div>
              <HeaderMenuItems classList={{ root: styles.headerMenuItems }} />
            </div>
            {activeFilters.length > 0 && isGreaterOrEqualTo(BREAKPOINTS.EXTRA_LARGE) && (
              <ActiveFilters activeFilters={activeFilters} invertedColors={marketplaceHeader} />
            )}
          </div>
          {!!marketplaceHeader && (
            <PopularSearchTerms popularSearch={featured_queries} categoriesInUrl={categoriesInUrl} />
          )}
        </header>
        {!!menuPopupOpened && <MenuDrawer />}
        {profile && (
          <>
            <RecentlyViewedPopup productIds={profile.recentlyViewedProducts} />
            <BookmarkPopup blogIds={profile.bookmarkedBlogs} />
            <PersonalInformationDrawer />
            <NewsletterSettingsDrawer />
          </>
        )}
      </>
    );
  }
);

StickyHeader.displayName = 'StickyHeader';
StickyHeader.propTypes = {
  classList: PropTypes.objectOf(PropTypes.string),
  config: CONFIG_SHAPE,
  searchTerm: PropTypes.string,
};

export const mapStateToProps = ({ config, intl, profile }) => ({
  config,
  intl,
  profile,
});

export default connect(mapStateToProps)(StickyHeader);
