import React from 'react';
import styles from './PopularSearchTerms.module.scss';
import { FormattedMessage } from 'react-intl';
import { getPagePath } from '../../utils/appRoutes';
import { ARD_SEARCH } from '../../utils/appRoutes.definitions';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import constructURL from '../../utils/constructURL';

const PopularSearchTerms = ({ classList = {}, popularSearch = [], categoriesInUrl = [] }) => {
  return (
    <div className={classList.root || styles.root}>
      <div className={classNames('container', classList.content || styles.content)}>
        <span>
          <FormattedMessage id="home.popularSearchTerms" defaultMessage="Popular search terms" />:
        </span>
        {popularSearch?.map((term) => (
          <Link
            key={term}
            to={constructURL(`${getPagePath(ARD_SEARCH, categoriesInUrl)}?q=${term}`)}
            className={styles.term}
          >
            {term.trim()}
          </Link>
        ))}
        {!popularSearch.length && (
          <FormattedMessage id="search.popularTerms" defaultMessage="Lego, Ultra HD TV, iPhone15">
            {(terms) =>
              typeof terms === 'string' &&
              terms.split(',').map((term) => (
                <Link key={term} to={`${getPagePath(ARD_SEARCH)}?q=${term.trim()}`} className={styles.term}>
                  {term.trim()}
                </Link>
              ))
            }
          </FormattedMessage>
        )}
      </div>
    </div>
  );
};

export default PopularSearchTerms;
